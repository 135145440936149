import * as React from "react"
import Container from '@mui/material/Container'
import { Box } from '@mui/material'
import CalculationDowntimeCost from '../components/CalculationDowntimeCost'

export const Head = () => (
  <>
    <title>HiMON® caluclation of downtime cost – HIGHVOLT</title>
    <meta name="viewport" content="initial-scale=1, width=device-width" />
    <meta name="robots" content="noindex,nofollow" />
  </>
)

const CalculationPage = () => {
  return (
    <Box className='hv-style'>
      <Container maxWidth="lg">
        <CalculationDowntimeCost />
      </Container>
    </Box>
  )
}

export default CalculationPage
